<template>
  <div class="container-page" v-if="item">
    <p class="title-inner">Карточка здравоохранения</p>

    <div class="row">
      <div class="form-group col-6 required">
        <ComponentInput
          label="Страна"
          :select2Options="countries"
          v-model="item.countryId"
          :select2Settings="select2SettingsNoClear"
          type="select2"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-6 required">
        <ComponentInput label="Год" v-model="item.year" type="number" required />
      </div>
      <div class="form-group col-6 required">
        <ComponentInput
          label="Месяц"
          :select2Options="months"
          v-model="item.month"
          :select2Settings="select2SettingsNoClear"
          type="select2"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-6">
        <ComponentInput label="Новые случаи заражения COVID-19" v-model="item.covidNewCaseCount" type="number" />
      </div>
      <div class="form-group col-6">
        <ComponentInput label="Количество вакцинированных" v-model="item.vaccinatedCount" type="number" />
      </div>
    </div>

    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import Constants from '@/common/constants';

  import CountryApi from '@/modules/nsi/api/country';

  import ComponentInput from '../../../common/components/ComponentInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import Api from '../api/healthcare.js';

  export default {
    name: 'HealthcareCard',
    components: { ComponentInput, ButtonStock },
    data() {
      return {
        id: this.$route.params.id,
        item: {},
        countries: [],
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
        },
        select2SettingsNoClear: Constants.select2SettingsNoClear,
        months: Constants.monthList,
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          Api.find(this.id).then((response) => {
            this.item = response.data;
          });
        }
      },
      loadDictionaries() {
        CountryApi.search(this.defaultRequest).then((response) => {
          this.countries = response.data.items.map((x) => {
            return { text: x.name, id: x.id };
          });
        });
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        Api.update(this.id, this.item)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.$router.push({ name: 'Healthcare' });
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Healthcare' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {};
    },
  };
</script>

<style lang="scss" scoped></style>
